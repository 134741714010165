import { useDispatch, useSelector } from "react-redux";
import AllNavbar from "../components/common/AllNavBar";
import { useEffect } from "react";
import { fleetList } from "../action/fleetAction";
import { BASE_IP } from "../env";

const FleetList = () => {
  const dispatch = useDispatch();
  const { loading, error, fleet } = useSelector((state) => state.FleetList);

  useEffect(() => {
    dispatch(fleetList());
  }, [dispatch]);

  return (
    <>
      <AllNavbar />

      {/* Show error if present */}
      {error && (
        <div className="text-center py-16">
          <p className="text-red-600 text-lg font-semibold">
            Error fetching fleet data.
          </p>
        </div>
      )}

      {/* Show loading animation if loading */}
      {loading && (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-600 border-solid"></div>
        </div>
      )}

      {/* Show no data message if no fleet data */}
      {!loading && !error && (!fleet || fleet.length === 0) && (
        <div className="text-center py-16">
          <p className="text-gray-600 text-lg font-semibold">
            No fleet data available.
          </p>
        </div>
      )}

      {/* Gradient Background Section */}
      {!loading && !error && fleet && fleet.length > 0 && (
        <>
          <div className="bg-gradient-to-b from-blue-400 to-white py-8 mt-20">
            <h1 className="text-4xl font-bold text-center text-gray-800">
              FLEET
            </h1>
          </div>

          <div className="relative px-[3%] md:px-[6%] mt-8 lg:mt-2">
            {/* Container for Text Descriptions */}
            <div className="flex overflow-x-auto space-x-8 mb-12">
              {fleet.map(({ id, vehicle_type, description }) => (
                <div
                  key={id}
                  className="flex-shrink-0 w-64 text-center bg-white p-4 rounded-lg shadow-md"
                >
                  <h2 className="text-xl font-semibold mb-2 text-gray-800">
                    {vehicle_type}
                  </h2>
                  <p className="text-gray-600">{description}</p>
                </div>
              ))}
            </div>

            {/* Container for Images */}
            <div className="flex overflow-x-auto space-x-8">
              {fleet.map(({ id, image, name }) => (
                <div key={id} className="flex-shrink-0 w-64 relative group">
                  <img
                    // src={`${BASE_IP}${image}`}
                    src={image}
                    alt={name}
                    className="w-full h-64 object-cover rounded-lg shadow-lg transition-transform duration-300 ease-in-out group-hover:scale-105"
                  />
                  <div className="absolute bottom-0 inset-x-0 bg-black bg-opacity-50 p-2 text-center">
                    <h3 className="text-white text-lg font-semibold">{name}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FleetList;
