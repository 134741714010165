import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { newsList } from "../../../action/newsAction";
import { BASE_IP } from "../../../env";

const BlogPostsList = () => {
  const dispatch = useDispatch();
  const { loading, error, news } = useSelector((state) => state.NewsList);

  useEffect(() => {
    dispatch(newsList());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex justify-center items-center py-16">
        <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-600 border-solid"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-16">
        <p className="text-red-600 text-lg font-semibold">
          Error fetching news.
        </p>
      </div>
    );
  }

  if (!news || news.length === 0) {
    return (
      <div className="text-center py-16">
        <p className="text-gray-600 text-lg font-semibold">
          No blog posts available.
        </p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="overflow-x-auto">
        <div className="flex space-x-8">
          {news.map((item) => (
            <div
              key={item.id}
              className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl w-80"
            >
              <Link to={`/blog/${item.id}`} className="block group">
                <img
                  // src={`${BASE_IP}${item.image}`}
                  src={item.image}
                  alt={item.title}
                  className="w-full h-64 object-cover transition-transform duration-300 ease-in-out group-hover:scale-110"
                />
              </Link>
              <div className="p-6">
                <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-3">
                  {item.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300 mb-4 leading-relaxed">
                  {item.content.length > 200
                    ? `${item.content.slice(0, 200)}...`
                    : item.content}
                </p>
                <Link
                  to={`/blog/${item.id}`}
                  className="inline-block bg-blue-600 text-white rounded-full px-6 py-2 text-xs font-medium uppercase leading-normal shadow-md hover:bg-blue-700 transition duration-150 ease-in-out"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPostsList;
