import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCar, FaHospital, FaShoppingCart, FaPlane } from "react-icons/fa";
import { serviceList } from "../action/serviceAction";
import { BASE_IP } from "../env";

const iconList = [FaCar, FaHospital, FaHospital, FaHospital]; // List of icons to use

const ServicesForServices = () => {
  const dispatch = useDispatch();
  const { loading, error, service } = useSelector((state) => state.ServiceList);

  useEffect(() => {
    dispatch(serviceList());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-600 border-solid"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-16 text-red-600">
        <p className="text-xl font-semibold">Error fetching services.</p>
      </div>
    );
  }

  if (!service || service.length === 0) {
    return (
      <div className="text-center py-16 text-gray-600">
        <p className="text-xl font-semibold">No services available.</p>
      </div>
    );
  }

  return (
    <div className="pt-16 pb-20 px-4">
      <div className="flex flex-col md:flex-row gap-8">
        {/* List of Services with Icons */}
        <div className="w-full md:w-1/3">
          <h2 className="text-4xl font-extrabold mb-6 text-gray-900">
            Our Services
          </h2>
          <ul className="space-y-6">
            {service.map(({ id, name, description }, index) => {
              const Icon = iconList[index % iconList.length]; // Select an icon
              return (
                <li
                  key={id}
                  className="flex items-start space-x-4 p-4 border rounded-lg shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-2xl"
                >
                  <div className="flex-shrink-0">
                    <Icon className="text-blue-500 text-2xl" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-800 mb-1">
                      {name}
                    </h3>
                    <p className="text-gray-600 text-base">{description}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        {/* Large Images with Hover Descriptions and Service Names at the Bottom */}
        <div className="w-full md:w-2/3">
          <div className="grid grid-cols-2 gap-4">
            {service.map(({ id, name, image, description }) => (
              <div key={id} className="relative w-full h-80">
                <div className="relative w-full h-full overflow-hidden rounded-lg shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
                  <img
                    // src={`${BASE_IP}${image}`} // Combine base URL with relative path
                    src={image}
                    alt={name}
                    className="w-full h-full object-cover transition-opacity duration-300 ease-in-out"
                  />
                  <div className="absolute bottom-0 inset-x-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <p className="text-white text-xl font-bold">{name}</p>
                  </div>
                  <div className="absolute inset-0 flex flex-col items-center justify-center p-6 bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100">
                    <div className="bg-white text-black p-6 rounded-lg shadow-lg transform translate-y-4 transition-transform duration-300 ease-in-out hover:translate-y-0">
                      <p className="text-base text-black font-sans tracking-wide leading-relaxed">
                        {description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesForServices;
