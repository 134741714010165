import { useDispatch, useSelector } from "react-redux";
import { serviceList } from "../action/serviceAction";
import AllNavbar from "../components/common/AllNavBar";
import {
  Categories,
  Counter,
  Feeds,
  Services as ServicesList,
  Team,
} from "../components/common/page-componets";
import { services } from "../data/dummyData";
import ServicesForServices from "./ServicesForSerivces";
import ServicesHome from "./ServicesHome";
import ServicesTwo from "./ServicesHome";

const Services = () => {

  return (
    <>
      <AllNavbar />

      {/* New Section with Gradient Background */}
      <div className="bg-gradient-to-b from-blue-400 to-white py-8 mt-20">
        <h1 className="text-4xl font-bold text-center text-gray-800">
          Our Services
        </h1>
      </div>

      <div className=" px-[3%] md:px-[6%] mt-8 lg:mt-2">
        <ServicesForServices />
        {/* <Vision /> */}
      </div>
    </>
  );
};

export default Services;
