export const PAYMENTRESERVE_LIST_REQUEST = "PAYMENTRESERVE_LIST_REQUEST";
export const PAYMENTRESERVE_LIST_SUCCESS = "PAYMENTRESERVE_LIST_SUCCESS";
export const PAYMENTRESERVE_LIST_FAIL = "PAYMENTRESERVE_LIST_FAIL";

export const RESERVE_CREATE_REQUEST = "RESERVE_CREATE_REQUEST";
export const RESERVE_CREATE_SUCCESS = "RESERVE_CREATE_SUCCESS";
export const RESERVE_CREATE_FAIL = "RESERVE_CREATE_FAIL";

export const PAYMENTRESERVE_DETAILS_REQUEST = "PAYMENTRESERVE_DETAILS_REQUEST";
export const PAYMENTRESERVE_DETAILS_SUCCESS = "PAYMENTRESERVE_DETAILS_SUCCESS";
export const PAYMENTRESERVE_DETAILS_FAIL = "PAYMENTRESERVE_DETAILS_FAIL";

export const PAYMENTRESERVE_CREATE_REQUEST = "PAYMENTRESERVE_CREATE_REQUEST";
export const PAYMENTRESERVE_CREATE_SUCCESS = "PAYMENTRESERVE_CREATE_SUCCESS";
export const PAYMENTRESERVE_CREATE_FAIL = "PAYMENTRESERVE_CREATE_FAIL";
export const PAYMENTRESERVE_CREATE_RESET = "PAYMENTRESERVE_CREATE_RESET";

export const PAYMENTRESERVE_UPDATE_REQUEST = "PAYMENTRESERVE_UPDATE_REQUEST";
export const PAYMENTRESERVE_UPDATE_SUCCESS = "PAYMENTRESERVE_UPDATE_SUCCESS";
export const PAYMENTRESERVE_UPDATE_FAIL = "PAYMENTRESERVE_UPDATE_FAIL";
export const PAYMENTRESERVE_UPDATE_RESET = "PAYMENTRESERVE_UPDATE_RESET";

export const PAYMENTRESERVE_DELETE_REQUEST = "PAYMENTRESERVE_DELETE_REQUEST";
export const PAYMENTRESERVE_DELETE_SUCCESS = "PAYMENTRESERVE_DELETE_SUCCESS";
export const PAYMENTRESERVE_DELETE_FAIL = "PAYMENTRESERVE_DELETE_FAIL";
export const PAYMENTRESERVE_DELETE_RESET = "PAYMENTRESERVE_DELETE_RESET";

export const PAYMENTRESERVE_CATEGORY_LIST_REQUEST =
  "PAYMENTRESERVE_CATEGORY_LIST_REQUEST";
export const PAYMENTRESERVE_CATEGORY_LIST_SUCCESS =
  "PAYMENTRESERVE_CATEGORY_LIST_SUCCESS";
export const PAYMENTRESERVE_CATEGORY_LIST_FAIL =
  "PAYMENTRESERVE_CATEGORY_LIST_FAIL";
