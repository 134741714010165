export const PROPERTIES_LIST_REQUEST = "PROPERTIES_LIST_REQUEST";
export const PROPERTIES_LIST_SUCCESS = "PROPERTIES_LIST_SUCCESS";
export const PROPERTIES_LIST_FAIL = "PROPERTIES_LIST_FAIL";

export const PROPERTIES_DETAILS_REQUEST = "PROPERTIES_DETAILS_REQUEST";
export const PROPERTIES_DETAILS_SUCCESS = "PROPERTIES_DETAILS_SUCCESS";
export const PROPERTIES_DETAILS_FAIL = "PROPERTIES_DETAILS_FAIL";

export const PROPERTIES_CREATE_REQUEST = "PROPERTIES_CREATE_REQUEST";
export const PROPERTIES_CREATE_SUCCESS = "PROPERTIES_CREATE_SUCCESS";
export const PROPERTIES_CREATE_FAIL = "PROPERTIES_CREATE_FAIL";
export const PROPERTIES_CREATE_RESET = "PROPERTIES_CREATE_RESET";

export const PROPERTIES_UPDATE_REQUEST = "PROPERTIES_UPDATE_REQUEST";
export const PROPERTIES_UPDATE_SUCCESS = "PROPERTIES_UPDATE_SUCCESS";
export const PROPERTIES_UPDATE_FAIL = "PROPERTIES_UPDATE_FAIL";
export const PROPERTIES_UPDATE_RESET = "PROPERTIES_UPDATE_RESET";

export const PROPERTIES_DELETE_REQUEST = "PROPERTIES_DELETE_REQUEST";
export const PROPERTIES_DELETE_SUCCESS = "PROPERTIES_DELETE_SUCCESS";
export const PROPERTIES_DELETE_FAIL = "PROPERTIES_DELETE_FAIL";
export const PROPERTIES_DELETE_RESET = "PROPERTIES_DELETE_RESET";

export const PROPERTIES_CATEGORY_LIST_REQUEST =
  "PROPERTIES_CATEGORY_LIST_REQUEST";
export const PROPERTIES_CATEGORY_LIST_SUCCESS =
  "PROPERTIES_CATEGORY_LIST_SUCCESS";
export const PROPERTIES_CATEGORY_LIST_FAIL = "PROPERTIES_CATEGORY_LIST_FAIL";
