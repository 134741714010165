import AllNavbar from "../components/common/AllNavBar";
import { BlogPostsList, Pagination } from "../components/common/page-componets";
import { feeds } from "../data/dummyData";

const Blog = () => {
  return (
    <>
      {" "}
      <AllNavbar />
      {/* New Section with Gradient Background */}
      <div className="bg-gradient-to-b from-blue-400 to-white py-8 mt-20">
        <h1 className="text-4xl font-bold text-center text-gray-800">
          Blog Posts
        </h1>
      </div>
      <div className="pt-20 px-[3%] md:px-[6%]">
        <BlogPostsList />
        <Pagination itemsPerPage={6} pageData={feeds} />
      </div>
    </>
  );
};

export default Blog;
