import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newsList } from "../../../action/newsAction";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { BASE_IP } from "../../../env";

const Feeds = () => {
  const dispatch = useDispatch();
  const { loading, error, news } = useSelector((state) => state.NewsList);

  useEffect(() => {
    dispatch(newsList());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-600 border-solid"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-16 text-red-600">
        <p className="text-xl font-semibold">Error fetching news.</p>
      </div>
    );
  }

  if (!news || news.length === 0) {
    return (
      <div className="text-center py-16 text-gray-600">
        <p className="text-xl font-semibold">No news available.</p>
      </div>
    );
  }

  return (
    <div className="pt-10 pb-16 bg-gray-50">
      {/* Section Header */}
      <div className="text-center mb-12">
        <h1
          className="text-4xl font-extrabold text-gray-900"
          style={{ color: "black" }}
        >
          Latest Newsfeeds
        </h1>
        <p className="text-gray-600 mt-2">
          Stay updated with our latest news and updates
        </p>
      </div>

      {/* News Grid */}
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        {news.map((item) => (
          <div
            key={item.id}
            className="bg-white rounded-lg shadow-lg overflow-hidden group transition-transform transform hover:scale-105"
          >
            <Link to={`/blog/${item.id}`} className="relative block">
              <img
                // src={`${BASE_IP}${item.image}`}
                src={item.image}
                alt={item.title}
                className="object-cover w-full h-64 rounded-t-lg"
              />
              <div className="absolute top-4 right-4 bg-orange-600 text-white px-3 py-1 text-xs font-semibold rounded-full">
                {/* Category Placeholder */}
                {/* {item.category} */}
              </div>
            </Link>
            <div className="p-6 flex flex-col">
              <Link
                to={`/blog/${item.id}`}
                className="text-lg font-semibold text-gray-800 hover:text-orange-600 transition-colors"
              >
                {item.title}
              </Link>
              <p className="mt-3 text-gray-600 text-sm">
                {item.content.length > 200
                  ? `${item.content.slice(0, 200)}...`
                  : item.content}
              </p>
              <Link
                to={`/blog/${item.id}`}
                className="mt-4 flex items-center text-orange-600 hover:underline"
              >
                <span className="uppercase">Read more</span>
                <FiArrowRight className="ml-2" />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Feeds;
