export const TESTIMONIAL_LIST_REQUEST = "TESTIMONIAL_LIST_REQUEST";
export const TESTIMONIAL_LIST_SUCCESS = "TESTIMONIAL_LIST_SUCCESS";
export const TESTIMONIAL_LIST_FAIL = "TESTIMONIAL_LIST_FAIL";

export const TESTIMONIAL_DETAILS_REQUEST = "TESTIMONIAL_DETAILS_REQUEST";
export const TESTIMONIAL_DETAILS_SUCCESS = "TESTIMONIAL_DETAILS_SUCCESS";
export const TESTIMONIAL_DETAILS_FAIL = "TESTIMONIAL_DETAILS_FAIL";

export const TESTIMONIAL_CREATE_REQUEST = "TESTIMONIAL_CREATE_REQUEST";
export const TESTIMONIAL_CREATE_SUCCESS = "TESTIMONIAL_CREATE_SUCCESS";
export const TESTIMONIAL_CREATE_FAIL = "TESTIMONIAL_CREATE_FAIL";
export const TESTIMONIAL_CREATE_RESET = "TESTIMONIAL_CREATE_RESET";

export const TESTIMONIAL_UPDATE_REQUEST = "TESTIMONIAL_UPDATE_REQUEST";
export const TESTIMONIAL_UPDATE_SUCCESS = "TESTIMONIAL_UPDATE_SUCCESS";
export const TESTIMONIAL_UPDATE_FAIL = "TESTIMONIAL_UPDATE_FAIL";
export const TESTIMONIAL_UPDATE_RESET = "TESTIMONIAL_UPDATE_RESET";

export const TESTIMONIAL_DELETE_REQUEST = "TESTIMONIAL_DELETE_REQUEST";
export const TESTIMONIAL_DELETE_SUCCESS = "TESTIMONIAL_DELETE_SUCCESS";
export const TESTIMONIAL_DELETE_FAIL = "TESTIMONIAL_DELETE_FAIL";
export const TESTIMONIAL_DELETE_RESET = "TESTIMONIAL_DELETE_RESET";

export const TESTIMONIAL_CATEGORY_LIST_REQUEST = "TESTIMONIAL_CATEGORY_LIST_REQUEST";
export const TESTIMONIAL_CATEGORY_LIST_SUCCESS = "TESTIMONIAL_CATEGORY_LIST_SUCCESS";
export const TESTIMONIAL_CATEGORY_LIST_FAIL = "TESTIMONIAL_CATEGORY_LIST_FAIL";
