import { services } from "../../../data/dummyData";

const Services = () => {
  return (
    <div className=" pb-16 bg-slate-50">
      {/* <div className="text-center">
        
        <h2 className=" section-title mx-auto text-4xl font-extrabold  text-black">
          Our Services
        </h2>
      </div> */}

      <div className="flex flex-wrap gap-4 mt-5">
        {services.map(({ id, name, image }) => (
          <div
            key={id}
            className="relative w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2"
          >
            <div className="relative w-full h-48 overflow-hidden rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
              <img
                src={image}
                alt={name}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent flex items-center justify-center">
                <h1 className="text-white text-xl font-bold">{name}</h1>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
