import {
  BiArrowFromLeft,
  BiArrowFromRight,
  BiCreditCard,
  BiGlobe,
  BiHomeAlt,
  BiRightArrow,
} from "react-icons/bi";

const AboutUs = () => {
  return (
    <div className="pt-16 pb-20">
      <div className="flex flex-wrap gap-24">
        <div className="relative flex-1 basis-[18rem] border">
          <img
            src="../../../../images/cars.jpg"
            alt="Mechot Real Estate"
            className="object-cover w-full h-full rounded-lg animate-zoom-in"
          />
        </div>
        <div className="relative flex-1 basis-[22rem]">
          {/* <h1
            className="mx-auto heading"
            style={{
              color: "orange",
              textAlign: "center",
              fontSize: "2em",
              margin: "20px 0",
            }}
          >
            About Us
          </h1> */}
          {/* <h2 className=" section-title mx-auto text-4xl font-extrabold  text-primary">
            About CareNetwork Logistics LLC
          </h2> */}
          {/* <h1 className="sub-heading text-3xl">about us</h1> */}
          <h1 className="heading"> CareNetwork Logistics LLC </h1>

          <p className="mt-2">
            CareNetWork Logistics is a trusted provider of non-emergency medical
            transportation services. Our mission is to ensure that every patient
            receives safe, timely, and compassionate transport to and from their
            medical appointments. service.
          </p>

          <div className="mt-4">
            <div className="flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiRightArrow />
              </div>
              <div>
                <h1 className="font-semibold capitalize">
                  Safety: Your health and safety are our top priorities.
                </h1>
              </div>
            </div>

            <div className="mt-3 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiRightArrow />
              </div>
              <div>
                <h1 className="font-semibold capitalize">
                  Reliability: We ensure punctual and dependable services.
                </h1>
                {/* <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Saepe, quisquam?
                </p> */}
              </div>
            </div>

            <div className="mt-3 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiRightArrow />
              </div>
              <div>
                <h1 className="font-semibold capitalize">
                  Compassion: Our team is dedicated to providing caring and
                  respectful service.
                </h1>
                {/* <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Saepe, quisquam?
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
