import axios from "axios";
import {
  SERVICE_LIST_FAIL,
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_SUCCESS,
} from "../constants/ServiceConstants";
import {
  FLEET_LIST_FAIL,
  FLEET_LIST_REQUEST,
  FLEET_LIST_SUCCESS,
} from "../constants/FleetConstranints";
import { BASE_IP } from "../env";

export const fleetList = () => async (dispatch) => {
  dispatch({ type: FLEET_LIST_REQUEST });

  try {
    const { data } = await axios.get(`${BASE_IP}/fleets/`);
    console.log("Service data @ action:", data);

    dispatch({
      type: FLEET_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    let errorMessage = "An error occurred while fetching fleets.";

    if (error.response && error.response.data) {
      errorMessage = error.response.data.message || errorMessage;
    } else if (error.message) {
      errorMessage = error.message;
    }

    console.error("Service fetch error:", errorMessage);

    dispatch({
      type: FLEET_LIST_FAIL,
      payload: errorMessage,
    });
  }
};
