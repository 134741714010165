import { BiMap } from "react-icons/bi";
import { FiMail, FiPhone } from "react-icons/fi";

const ContactInfo = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
        Information
      </h2>
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
        <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-6 border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-xl">
          <div className="bg-blue-500 text-white rounded-full p-4 mb-4">
            <FiPhone className="text-3xl" />
          </div>
          <h3 className="text-xl font-semibold text-gray-800 mb-2">
            Phone Number
          </h3>
          <p className="text-lg text-gray-600"> (571) 277- 4855</p>
        </div>
        <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-6 border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-xl">
          <div className="bg-green-500 text-white rounded-full p-4 mb-4">
            <FiMail className="text-3xl" />
          </div>
          <h3 className="text-xl font-semibold text-gray-800 mb-2">
            Email Address
          </h3>
          <p className="text-lg text-gray-600">info@carenetworklogistics.com</p>
        </div>
        <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-6 border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-xl">
          <div className="bg-red-500 text-white rounded-full p-4 mb-4">
            <BiMap className="text-3xl" />
          </div>
          <h3 className="text-xl font-semibold text-gray-800 mb-2">
            Office Address
          </h3>
          <p className="text-lg text-gray-600">
            9105 Ayden Ln, Lorton VA, 22079
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
