export const FLEET_LIST_REQUEST = "FLEET_LIST_REQUEST";
export const FLEET_LIST_SUCCESS = "FLEET_LIST_SUCCESS";
export const FLEET_LIST_FAIL = "FLEET_LIST_FAIL";

export const FLEET_DETAILS_REQUEST = "FLEET_DETAILS_REQUEST";
export const FLEET_DETAILS_SUCCESS = "FLEET_DETAILS_SUCCESS";
export const FLEET_DETAILS_FAIL = "FLEET_DETAILS_FAIL";

export const FLEET_CREATE_REQUEST = "FLEET_CREATE_REQUEST";
export const FLEET_CREATE_SUCCESS = "FLEET_CREATE_SUCCESS";
export const FLEET_CREATE_FAIL = "FLEET_CREATE_FAIL";
export const FLEET_CREATE_RESET = "FLEET_CREATE_RESET";

export const FLEET_UPDATE_REQUEST = "FLEET_UPDATE_REQUEST";
export const FLEET_UPDATE_SUCCESS = "FLEET_UPDATE_SUCCESS";
export const FLEET_UPDATE_FAIL = "FLEET_UPDATE_FAIL";
export const FLEET_UPDATE_RESET = "FLEET_UPDATE_RESET";

export const FLEET_DELETE_REQUEST = "FLEET_DELETE_REQUEST";
export const FLEET_DELETE_SUCCESS = "FLEET_DELETE_SUCCESS";
export const FLEET_DELETE_FAIL = "FLEET_DELETE_FAIL";
export const FLEET_DELETE_RESET = "FLEET_DELETE_RESET";

export const FLEET_CATEGORY_LIST_REQUEST = "FLEET_CATEGORY_LIST_REQUEST";
export const FLEET_CATEGORY_LIST_SUCCESS = "FLEET_CATEGORY_LIST_SUCCESS";
export const FLEET_CATEGORY_LIST_FAIL = "FLEET_CATEGORY_LIST_FAIL";
