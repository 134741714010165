import ReactPlayer from "react-player";

const VideoPlayer = () => {
  return (
    <div style={{ position: "relative", paddingTop: "56.25%" }}>
      <ReactPlayer
        url="/Video/video.mp4" // Replace with the correct relative path
        style={{ position: "absolute", top: 0, left: 0 }}
        width="100%"
        height="100%"
        controls={true}
        playing={false}
        loop={true}
        muted={true}
        config={{
          file: {
            attributes: {
              poster: "/Video/Scre.png", // Replace with the correct relative path
            },
          },
        }}
      />
    </div>
  );
};

export default VideoPlayer;
