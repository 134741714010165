
import { GALLERY_LIST_FAIL, GALLERY_LIST_REQUEST, GALLERY_LIST_SUCCESS } from "../constants/GalleryConstants";
import {
  CLIENT_LIST_FAIL,
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
} from "../constants/ClientConstants";

  export const galleryListReducer = (
    state = { loading: true, gallerys: [] },
    action
  ) => {
    switch (action.type) {
      case GALLERY_LIST_REQUEST:
        return {
          loading: true,
        };
      case GALLERY_LIST_SUCCESS:
        return {
          loading: false,
          gallerys: action.payload,
        };
      case GALLERY_LIST_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
