import AllNavbar from "../components/common/AllNavBar";
import { ContactInfo, Form, Map } from "../components/contact";

const Contact = () => {
  return (
    <>
      <AllNavbar />

      {/* New Section with Gradient Background */}
      <div className="bg-gradient-to-b from-blue-400 to-white py-8 mt-24">
        <h1 className="text-4xl font-bold text-center text-gray-800">
          Contact
        </h1>
      </div>

      <div className=" px-[3%] md:px-[6%] mt-28 lg:mt-8 md:16">
        <ContactInfo />
        <Form />

        <Map />
      </div>
    </>
  );
};

export default Contact;
