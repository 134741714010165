import Navbar from "../components/common/Navbar";
import {
  AboutUs,
  Brands,
  Feeds,
  Testimonial,
} from "../components/common/page-componets";
import AllNavbar from "../components/common/AllNavBar";
import Vision from "../components/common/page-componets/Vision";

const About = () => {
  return (
    <>
      <AllNavbar />

      {/* New Section with Gradient Background */}
      <div className="bg-gradient-to-b from-blue-400 to-white py-8 mt-20">
        <h1 className="text-4xl font-bold text-center text-gray-800">
          About Us
        </h1>
      </div>

      <div className="px-[3%] md:px-[6%] mt-8 lg:mt-2">
        <AboutUs />
      </div>

      {/* Team Members Section */}
      <div className="bg-blue-50 py-12 mt-12 rounded-lg shadow-lg">
        <div className="max-w-4xl mx-auto px-6">
          <h2 className="text-4xl font-bold text-center text-gray-900 mb-6">
            Meet Our Team
          </h2>
          <p className="text-center text-gray-700 mb-8 text-lg leading-relaxed">
            Our dedicated team of professional drivers and healthcare staff are
            committed to delivering exceptional service and care. Each team
            member is certified and undergoes ongoing training to ensure they
            are equipped with the latest safety protocols and best practices.
          </p>
          <div className="flex justify-center">
            <a
              href="/contact"
              className="bg-blue-600 text-white py-2 px-6 rounded-lg text-lg font-medium hover:bg-blue-700 transition duration-300 ease-in-out"
            >
              Meet the Team
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
