import {
  CAREER_LIST_FAIL,
  CAREER_LIST_REQUEST,
  CAREER_LIST_SUCCESS,
} from "../constants/CareerConstants";
import {
  PAYMENTRESERVE_CREATE_FAIL,
  PAYMENTRESERVE_CREATE_REQUEST,
  PAYMENTRESERVE_CREATE_RESET,
  PAYMENTRESERVE_CREATE_SUCCESS,
  RESERVE_CREATE_FAIL,
  RESERVE_CREATE_REQUEST,
  RESERVE_CREATE_SUCCESS,
} from "../constants/PayementReserveConstants";

export const paymenetReserveCreateReducer = (
  state = { loading: true, reserve: [] },
  action
) => {
  switch (action.type) {
    case PAYMENTRESERVE_CREATE_REQUEST:
      return {
        loading: true,
      };
    case PAYMENTRESERVE_CREATE_SUCCESS:
      return {
        loading: false,
        career: action.payload,
      };
    case PAYMENTRESERVE_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const ReserveCreateReducer = (
  state = { loading: true, reserve: [] },
  action
) => {
  switch (action.type) {
    case RESERVE_CREATE_REQUEST:
      return {
        loading: true,
      };
    case RESERVE_CREATE_SUCCESS:
      return {
        loading: false,
        career: action.payload,
      };
    case RESERVE_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
