import { useEffect, useRef } from "react";
import { FiCheck, FiLayers, FiUsers } from "react-icons/fi";
import { Link } from "react-router-dom";

const Speciality = () => {
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-fadeInUp");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return (
    <div className="py-16">
      <div className="flex flex-wrap gap-10">
        <div className="flex-1 basis-[20rem]">
          <h1 className="heading">
            {" "}
            Work For CareNetwork Logistics Transport!{" "}
          </h1>
          {/* <h2 className="text-2xl font-bold mb-4"></h2> */}
          <p className="mt-3 text-black">
            We are dedicated to finding the best employees who share a passion
            in helping others! We are always looking for individuals that can
            appreciate and join with us in our commitment to compassionate,
            safe, and courteous service.
          </p>
          {/* <p className="mt-3">
            We offer modern and comfortable homes in a convenient residential
            area of Addis Ababa.
          </p> */}
          <div className="mt-4">
            <Link to="/about-us" className="btn btn-primary">
              Read More
            </Link>
          </div>
        </div>
        <div className="flex-1 basis-[20rem]">
          <img
            src="../../../../images/car3.jpg"
            alt="Mechot Real Estate"
            className="object-cover w-full h-full rounded-lg animate-zoom-in"
          />
        </div>
      </div>
    </div>
  );
};

export default Speciality;
