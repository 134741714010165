import React from "react";
import { FiChevronUp, FiSend } from "react-icons/fi";

const BackToTopButton = ({ showButton }) => {
  return (
    <a href="#">
      <button
        className={`fixed bottom-0 right-0 grid items-center justify-center mb-4 mr-4 z-30 rounded-full shadow back-to-top-btn w-16 h-16 bg-primary text-white text-lg ${
          showButton ? "active" : ""
        }`}
        title="Join our Telegram group" // Tooltip text displayed on hover
        // onClick={() => window.scrollTo(0, 0)}
      >
        <FiSend size={32} /> {/* Adjust the size of the icon here */}
      </button>
    </a>
  );
};

export default BackToTopButton;
