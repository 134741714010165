import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useDispatch } from "react-redux";

const Map = () => {
  const mapContainerStyle = {
    width: "100%",
    height: "600px",
  };

  const center = {
    lat: 38.710508,
    lng: -77.229591,
  };

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [loadError, setLoadError] = useState(null);
  const mapRef = useRef(null);

  const onSelectMarker = (marker) => {
    setSelectedMarker(marker);
  };

  const onCloseInfoWindow = () => {
    setSelectedMarker(null);
  };

  const api = "AIzaSyB8cS0kZ25Id4FJi7aGC587nCnTKwg7Dio";

  const dispatch = useDispatch();

  useEffect(() => {
    setMapLoaded(true);
  }, []);

  return (
    <div>
      {loadError ? (
        <div className="error-message">
          <p>Error loading Google Maps: {loadError.message}</p>
        </div>
      ) : (
        <LoadScript
          googleMapsApiKey={api}
          onLoad={() => setMapLoaded(true)}
          onError={(error) => setLoadError(error)}
        >
          {mapLoaded ? (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={15}
              onLoad={(map) => (mapRef.current = map)}
            >
              <Marker
                position={center}
                onClick={() => onSelectMarker(center)}
                icon={{
                  url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                }}
              />

              {selectedMarker && (
                <InfoWindow
                  position={selectedMarker}
                  onCloseClick={onCloseInfoWindow}
                >
                  <div>
                    <h3>Marker Information</h3>
                    <p>Latitude: {selectedMarker.lat}</p>
                    <p>Longitude: {selectedMarker.lng}</p>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          ) : (
            <div className="loading-message">
              <p>Loading map...</p>
            </div>
          )}
        </LoadScript>
      )}
    </div>
  );
};

export default Map;
