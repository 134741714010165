import {
  PROPERTIES_DETAILS_FAIL,
  PROPERTIES_DETAILS_REQUEST,
  PROPERTIES_DETAILS_SUCCESS,
  PROPERTIES_LIST_FAIL,
  PROPERTIES_LIST_REQUEST,
  PROPERTIES_LIST_SUCCESS,
} from "../constants/PropertiesCOnstants";

export const propertiesListReducer = (
  state = { loading: true, properties: [] },
  action
) => {
  switch (action.type) {
    case PROPERTIES_LIST_REQUEST:
      return {
        loading: true,
      };
    case PROPERTIES_LIST_SUCCESS:
      return {
        loading: false,
        properties: action.payload,
      };
    case PROPERTIES_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const propertiesDetailListReducer = (
  state = { loading: true, propertiesbyID: [] },
  action
) => {
  switch (action.type) {
    case PROPERTIES_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case PROPERTIES_DETAILS_SUCCESS:
      return {
        loading: false,
        projectbyID: action.payload,
      };
    case PROPERTIES_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
