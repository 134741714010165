import {
  BOOK_CREATE_REQUEST,
  BOOK_CREATE_SUCCESS,
  BOOK_CREATE_FAIL,
} from "../constants/bookCOnstranitns";

// Initial state for book creation
const initialState = {
  loading: false,
  book: null, // Store the newly created book
  error: null,
};

// Reducer function for book creation
export const bookCreateReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOK_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BOOK_CREATE_SUCCESS:
      return {
        loading: false,
        book: action.payload,
        error: null,
      };
    case BOOK_CREATE_FAIL:
      return {
        loading: false,
        book: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
