import React, { useState } from "react";

const PayementList = () => {
  const [openIndices, setOpenIndices] = useState([0]); // Set the initial state to include the index of the first item

  const toggleAccordion = (index) => {
    setOpenIndices((prevOpenIndices) =>
      prevOpenIndices.includes(index)
        ? prevOpenIndices.filter((i) => i !== index)
        : [...prevOpenIndices, index]
    );
  };

  const accordionData = [
    {
      title: "Option One",
      content:
        "100% payment will have 1,000,000 birr(One million Birr) discount. ",
    },
    {
      title: "Option Two",
      content:
        "Down payment 60% \nFinal payment (payable after     one year): 40% It will have 500,000 (Five Hundred Thousand ) Birr discount ",
    },
    {
      title: "Option Three",
      content:
        "Flexible payment option\nDown payment 10%\nFirst payment 25% During the construction of Basements and Ground floor (For Substructure work)\nSecond payment 40% During  the entire floor of the  building is under construction (For Superstructure work)\nFinal payment 25% For Semi-finishing work \nN.B:- The installments will be paid out over a 48-month payment schedule.",
    },
    {
      title: "Option Four",
      content:
        "Shop payment option \nDown payment 20%\nThe remaining 80% will be paid out over a 36-month payment schedule. ",
    },
  ];

  return (
    <div id="accordion-open" data-accordion="open">
      <h2 className=" section-title mx-auto text-4xl font-extrabold  text-primary">
        Payment Options
      </h2>
      {accordionData.map((item, index) => (
        <div key={index}>
          <h2 id={`accordion-open-heading-${index + 1}`}>
            <button
              type="button"
              className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
              data-accordion-target={`#accordion-open-body-${index + 1}`}
              aria-expanded={openIndices.includes(index)}
              aria-controls={`accordion-open-body-${index + 1}`}
              onClick={() => toggleAccordion(index)}
            >
              <span className="flex items-center justify-center w-full text-xl font-bold text-primary">
                {/* {index === 3 ? <h2>Shop payment option</h2> : ""} */}
                {item.title}
              </span>

              <svg
                data-accordion-icon
                className={`w-3 h-3 transition-transform duration-300 ${
                  openIndices.includes(index) ? "transform rotate-180" : ""
                } shrink-0`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5 5 1 1 5"
                />
              </svg>
            </button>
          </h2>
          <div
            id={`accordion-open-body-${index + 1}`}
            className={`p-5 border border-b-0 border-gray-200 dark:border-gray-700 ${
              openIndices.includes(index) ? "" : "hidden"
            }`}
            aria-labelledby={`accordion-open-heading-${index + 1}`}
          >
            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
              <ul className="list-disc pl-6">
                {item.content.split("\n").map((point, i) => (
                  <li
                    key={i}
                    className="mb-2 text-gray-500 text-xl dark:text-gray-400"
                  >
                    {point.trim()}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  // return (
  //   <div id="accordion-open" data-accordion="open">
  //     <h1
  //       className="mx-auto heading"
  //       style={{
  //         color: "orange",
  //         textAlign: "center",
  //         fontSize: "2em",
  //         margin: "20px 0",
  //       }}
  //     >
  //       Payment Options
  //     </h1>
  //     {accordionData.map((item, index) => (
  //       <div key={index}>
  //         <h2 id={`accordion-open-heading-${index + 1}`}>
  //           <button
  //             type="button"
  //             className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
  //             data-accordion-target={`#accordion-open-body-${index + 1}`}
  //             aria-expanded={openIndices.includes(index)}
  //             aria-controls={`accordion-open-body-${index + 1}`}
  //             onClick={() => toggleAccordion(index)}
  //           >
  //             <span className="flex items-center justify-center">
  //               <svg
  //                 className="w-5 h-5 me-2 shrink-0"
  //                 fill="currentColor"
  //                 viewBox="0 0 20 20"
  //                 xmlns="http://www.w3.org/2000/svg"
  //               >
  //                 <path
  //                   fillRule="evenodd"
  //                   d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
  //                   clipRule="evenodd"
  //                 ></path>
  //               </svg>
  //               {item.title}
  //             </span>

  //             <svg
  //               data-accordion-icon
  //               className={`w-3 h-3 rotate-${
  //                 openIndices.includes(index) ? "180" : "0"
  //               } shrink-0`}
  //               aria-hidden="true"
  //               xmlns="http://www.w3.org/2000/svg"
  //               fill="none"
  //               viewBox="0 0 10 6"
  //             >
  //               <path
  //                 stroke="currentColor"
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //                 strokeWidth="2"
  //                 d="M9 5 5 1 1 5"
  //               />
  //             </svg>
  //           </button>
  //         </h2>
  //         <div
  //           id={`accordion-open-body-${index + 1}`}
  //           className={`p-5 border border-b-0 border-gray-200 dark:border-gray-700 ${
  //             openIndices.includes(index) ? "" : "hidden"
  //           }`}
  //           aria-labelledby={`accordion-open-heading-${index + 1}`}
  //         >
  //           <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
  //             <p className="mb-2 text-gray-500 dark:text-gray-400">
  //               {item.content}
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     ))}
  //   </div>
  // );
};

export default PayementList;
