export const GALLERY_LIST_REQUEST = "GALLERY_LIST_REQUEST";
export const GALLERY_LIST_SUCCESS = "GALLERY_LIST_SUCCESS";
export const GALLERY_LIST_FAIL = "GALLERY_LIST_FAIL";

// export const GALLERY_DETAILS_REQUEST = "GALLERY_DETAILS_REQUEST";
// export const GALLERY_DETAILS_SUCCESS = "GALLERY_DETAILS_SUCCESS";
// export const GALLERY_DETAILS_FAIL = "GALLERY_DETAILS_FAIL";

// export const GALLERY_CREATE_REQUEST = "GALLERY_CREATE_REQUEST";
// export const GALLERY_CREATE_SUCCESS = "GALLERY_CREATE_SUCCESS";
// export const GALLERY_CREATE_FAIL = "GALLERY_CREATE_FAIL";
// export const GALLERY_CREATE_RESET = "GALLERY_CREATE_RESET";

// export const GALLERY_UPDATE_REQUEST = "GALLERY_UPDATE_REQUEST";
// export const GALLERY_UPDATE_SUCCESS = "GALLERY_UPDATE_SUCCESS";
// export const GALLERY_UPDATE_FAIL = "GALLERY_UPDATE_FAIL";
// export const GALLERY_UPDATE_RESET = "GALLERY_UPDATE_RESET";


