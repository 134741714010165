export const BOOK_LIST_REQUEST = "BOOK_LIST_REQUEST";
export const BOOK_LIST_SUCCESS = "BOOK_LIST_SUCCESS";
export const BOOK_LIST_FAIL = "BOOK_LIST_FAIL";

export const BOOK_DETAILS_REQUEST = "BOOK_DETAILS_REQUEST";
export const BOOK_DETAILS_SUCCESS = "BOOK_DETAILS_SUCCESS";
export const BOOK_DETAILS_FAIL = "BOOK_DETAILS_FAIL";

export const BOOK_CREATE_REQUEST = "BOOK_CREATE_REQUEST";
export const BOOK_CREATE_SUCCESS = "BOOK_CREATE_SUCCESS";
export const BOOK_CREATE_FAIL = "BOOK_CREATE_FAIL";
export const BOOK_CREATE_RESET = "BOOK_CREATE_RESET";

export const BOOK_UPDATE_REQUEST = "BOOK_UPDATE_REQUEST";
export const BOOK_UPDATE_SUCCESS = "BOOK_UPDATE_SUCCESS";
export const BOOK_UPDATE_FAIL = "BOOK_UPDATE_FAIL";
export const BOOK_UPDATE_RESET = "BOOK_UPDATE_RESET";

export const BOOK_DELETE_REQUEST = "BOOK_DELETE_REQUEST";
export const BOOK_DELETE_SUCCESS = "BOOK_DELETE_SUCCESS";
export const BOOK_DELETE_FAIL = "BOOK_DELETE_FAIL";
export const BOOK_DELETE_RESET = "BOOK_DELETE_RESET";

export const BOOK_CATEGORY_LIST_REQUEST = "BOOK_CATEGORY_LIST_REQUEST";
export const BOOK_CATEGORY_LIST_SUCCESS = "BOOK_CATEGORY_LIST_SUCCESS";
export const BOOK_CATEGORY_LIST_FAIL = "BOOK_CATEGORY_LIST_FAIL";
