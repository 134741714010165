import AllNavbar from "../components/common/AllNavBar";
import {
  AboutUs,
  Brands,
  Categories,
  Featured,
  Team,
  WhatWeDo,
} from "../components/common/page-componets";
import { Speciality } from "../components/home/home-1";
import VideoPlayer from "../components/home/home-1/Video";
import {
  Filters,
  GetInTouch,
  Hero,
  Testimonial,
} from "../components/home/home-3";
import Services from "./Services";
import { Feeds } from "../components/home/home-1";
import ServicesHome from "./ServicesHome";
const HomeThree = () => {
  return (
    <>
      {" "}
      <AllNavbar />
      <div className="md:pt-[5.2rem] pt-10">
        <Hero />
        {/* <Filters /> */}
        <div className="mt-10 px-[3%] md:px-[6%]">
          <AboutUs />

          {/* <Invest /> */}
          {/* <VideoPlayer />/ */}
          <ServicesHome />
          <Speciality />
          {/* <Featured />
          <Categories />
          <GetInTouch /> */}
          {/* <Team /> */}
          {/* <Testimonial /> */}
          {/* <Feeds /> */}
          {/* <Brands /> */}
        </div>
      </div>
    </>
  );
};

export default HomeThree;
