import {
  FLEET_LIST_FAIL,
  FLEET_LIST_REQUEST,
  FLEET_LIST_SUCCESS,
} from "../constants/FleetConstranints";
import {
  SERVICE_LIST_FAIL,
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_SUCCESS,
} from "../constants/ServiceConstants";

export const fleetListReducer = (
  state = { loading: true, fleet: [] },
  action
) => {
  switch (action.type) {
    case FLEET_LIST_REQUEST:
      return {
        loading: true,
      };
    case FLEET_LIST_SUCCESS:
      return {
        loading: false,
        fleet: action.payload,
      };
    case FLEET_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
