import { useDispatch, useSelector } from "react-redux";
import { serviceList } from "../action/serviceAction";
import { useEffect } from "react";

const ServicesHome = () => {
  const dispatch = useDispatch();
  const { loading, error, service } = useSelector((state) => state.ServiceList);

  useEffect(() => {
    dispatch(serviceList());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-600 border-solid"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-16 text-red-600">
        <p className="text-xl font-semibold">Error fetching services.</p>
      </div>
    );
  }

  if (service.length === 0) {
    return (
      <div className="text-center py-16 text-gray-600">
        <p className="text-xl font-semibold">
          No services available at the moment.
        </p>
      </div>
    );
  }

  return (
    <div className="pt-16 pb-20 px-4">
      <div className="text-center mb-10">
        <h2 className="section-title text-4xl font-extrabold text-black mb-4 font-sans">
          Our Services
        </h2>
      </div>
      <div className="flex flex-wrap -mx-2">
        {service?.map(({ id, name, image, description }) => (
          <div key={id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2">
            <div className="relative overflow-hidden rounded-lg shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
              <img
                src={image}
                alt={name}
                className="w-full h-80 object-cover transition-opacity duration-300 ease-in-out"
              />
              <div className="absolute inset-0 flex flex-col items-center justify-center p-6 bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100">
                <div className="bg-white text-black p-6 rounded-lg shadow-lg transform translate-y-4 transition-transform duration-300 ease-in-out hover:translate-y-0">
                  <p className="text-base text-black font-sans tracking-wide leading-relaxed">
                    {description}
                  </p>
                </div>
              </div>
              <div className="absolute bottom-0 inset-x-0 bg-black bg-opacity-50 p-4 text-center">
                <h3 className="text-white text-xl font-semibold font-sans tracking-wide leading-tight">
                  {name}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesHome;
