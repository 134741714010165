import axios from "axios";
import {
  SERVICE_LIST_FAIL,
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_SUCCESS,
} from "../constants/ServiceConstants";
import {BASE_IP} from "../env";




export const serviceList = () => async (dispatch) => {
  dispatch({ type: SERVICE_LIST_REQUEST });

  try {
    const { data } = await axios.get(`${BASE_IP}/services/`);
    console.log("Service data @ action:", data);

    dispatch({
      type: SERVICE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    let errorMessage = "An error occurred while fetching services.";

    if (error.response && error.response.data) {
      errorMessage = error.response.data.message || errorMessage;
    } else if (error.message) {
      errorMessage = error.message;
    }

    console.error("Service fetch error:", errorMessage);

    dispatch({
      type: SERVICE_LIST_FAIL,
      payload: errorMessage,
    });
  }
};
