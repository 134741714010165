import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { faqsList } from "../../action/faqActions";
import SingleFaq from "./SingleFaq";
import AllNavbar from "../common/AllNavBar";
// import AllNavbar from "../components/common/AllNavBar"; // Import the AllNavbar component

const FaqsList = () => {
  const dispatch = useDispatch();
  const { loading, error, faq } = useSelector((state) => state.FaqList);

  useEffect(() => {
    dispatch(faqsList());
  }, [dispatch]);

  return (
    <>
      <AllNavbar />

      {/* Conditional Rendering for Loading, Error, and No Data States */}
      {loading && (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-600 border-solid"></div>
        </div>
      )}

      {error && (
        <p className="text-center py-8 text-red-600">
          Error fetching FAQ data.
        </p>
      )}

      {!loading && !error && (!faq || faq.length === 0) && (
        <div className="text-center py-8">
          <p className="text-gray-600 text-lg font-semibold">
            No FAQ data available.
          </p>
        </div>
      )}

      {/* FAQ List */}
      {!loading && !error && faq && faq.length > 0 && (
        <div className="container mx-auto px-4 py-8">
          {/* Section Header */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold text-gray-800">
              Frequently Asked Questions
            </h1>
            <p className="text-gray-600 mt-2">
              Find answers to common questions below
            </p>
          </div>

          {/* FAQ List */}
          <div className="space-y-6">
            {faq.map((item) => (
              <SingleFaq
                key={item.id}
                question={item.question}
                response={item.answer}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default FaqsList;
