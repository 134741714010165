import { useState } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";

const SingleFaq = ({ question, response }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setIsActive(!isActive);
  };

  return (
    <div className="border border-gray-200 rounded-lg shadow-md mb-4">
      <div
        className={`flex items-center justify-between p-4 cursor-pointer bg-gray-100 dark:bg-gray-800 rounded-t-lg transition-all duration-300 ease-in-out ${
          isActive
            ? "bg-blue-100 dark:bg-blue-600 text-blue-800 dark:text-blue-200"
            : "text-gray-800 dark:text-gray-200"
        }`}
        onClick={handleToggle}
      >
        <h1 className="text-xl font-semibold">{question}</h1>
        {isOpen ? (
          <BiMinus className="text-xl" />
        ) : (
          <BiPlus className="text-xl" />
        )}
      </div>
      <div
        className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
          isOpen ? "max-h-screen p-4" : "max-h-0"
        }`}
      >
        <p className="text-gray-600 dark:text-gray-300">{response}</p>
      </div>
    </div>
  );
};

export default SingleFaq;
