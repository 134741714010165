import { useEffect } from "react";
import { FiDelete, FiMoon, FiSun } from "react-icons/fi";
import { BiMenu } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link, useNavigate } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { navLinks } from "../../data/navLinks";
import SingleLink from "./SingleLink";
import {
  uiStore,
  closeDropdown,
  closeSidebar,
  openSidebar,
  toggleDarkMode,
} from "../../features/uiSlice";

const AllNavbar = () => {
  const rootDoc = document.querySelector(":root");
  const { darkMode, isSidebarOpen } = useSelector(uiStore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDarkMode = () => {
    dispatch(toggleDarkMode());
  };

  useEffect(() => {
    if (darkMode) rootDoc.classList.add("dark");
    else rootDoc.classList.remove("dark");
    localStorage.setItem("Martvilla-theme-mode", JSON.stringify(darkMode));
  }, [darkMode]);

  const handleClose = (e) => {
    if (!e.target.classList.contains("link")) {
      dispatch(closeDropdown());
    }
  };

  const handleCloseSidebar = (e) => {
    if (e.target.classList.contains("mobile-modal")) dispatch(closeSidebar());
  };

  return (
    <>
      {/* Social Media and Phone Contact */}
      <div className="fixed top-0 left-0 w-full bg-blue-200 dark:bg-gray-900 p-2 justify-between items-center z-30 hidden sm:flex">
        <div className="flex space-x-4">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="text-xl font-bold text-black dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 transition-colors duration-300" />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="text-xl font-bold text-black dark:text-gray-400 hover:text-pink-600 dark:hover:text-pink-500 transition-colors duration-300" />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="relative w-6 h-6 flex items-center justify-center">
              <span className="absolute text-xl font-bold text-black dark:text-gray-400">
                X
              </span>
              <FaTwitter className="absolute text-xl font-bold text-transparent" />
            </div>
          </a>
          <a
            href="https://youtube.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube className="text-xl font-bold text-black dark:text-gray-400 hover:text-red-600 dark:hover:text-red-500 transition-colors duration-300" />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="text-xl font-bold text-black dark:text-gray-400 hover:text-blue-700 dark:hover:text-blue-600 transition-colors duration-300" />
          </a>
        </div>
        <div className="text-center text-lg font-bold text-black dark:text-gray-400 mx-auto hidden sm:flex">
          Call us: (571) 277-4855
        </div>
      </div>

      {/* Call Us - Mobile Only */}
      <div className="fixed top-0 left-0 w-full bg-blue-200 dark:bg-gray-900 p-2 justify-between items-center z-30 sm:hidden">
        <div className="text-center text-lg font-bold text-black dark:text-gray-400 mx-auto">
          Call us: (571) 277-4855
        </div>
      </div>

      {/* Main Navigation Bar */}
      <div
        className="navbar fixed top-9 left-0 w-full h-[60px] bg-white dark:bg-gray-800 shadow-md backdrop-blur-md z-20 px-4 md:px-8 flex items-center justify-between transition-all duration-300 mb-0 lg:mb-8"
        onMouseOver={handleClose}
      >
        {/* Company Name */}
        <div className="flex items-center space-x-2">
          <Link to="/" className="flex items-center space-x-2">
            <img
              src="../../../images/care_logo-removebg-preview.png"
              alt="CareNetwork Logistics LLC Logo"
              className="w-10 h-10 md:w-20 md:h-20"
            />
            <h1 className="text-xl font-bold text-gray-900 dark:text-white">
              CareNetwork Logistics LLC
            </h1>
          </Link>
        </div>

        {/* Navigation Links */}
        <div className="flex items-center space-x-6 ml-auto">
          <div className="hidden md:flex items-center space-x-2">
            {navLinks.map((link) => (
              <SingleLink {...link} key={link.id} />
            ))}
          </div>

          {/* Mobile Menu Toggle */}
          <div
            className="md:hidden flex items-center space-x-2 cursor-pointer"
            onClick={() => dispatch(openSidebar())}
          >
            <BiMenu className="text-2xl" />
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 bg-black/50 z-50 transition-opacity duration-300 ${
          isSidebarOpen
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
        onClick={handleCloseSidebar}
      >
        <ul
          className={`fixed top-0 left-0 w-64 bg-white dark:bg-gray-800 h-full p-4 transition-transform duration-300 ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="flex items-center justify-between border-b pb-4 mb-4 dark:border-gray-700">
            <p className="text-xl font-semibold">Menu</p>
            <div
              className="p-2 cursor-pointer"
              onClick={() => dispatch(closeSidebar())}
            >
              <FiDelete className="text-xl" />
            </div>
          </div>
          <p className="text-2xl font-semibold mb-4 text-center">
            CareNetwork Logistics LLC
          </p>
          <div className="flex flex-col items-center space-y-4">
            {navLinks.map(({ id, linkText, url, subLinks }) => (
              <li key={id} className="w-full text-center">
                <NavLink
                  to={url}
                  className="text-xl font-extrabold dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-300 block"
                  onClick={() => dispatch(closeSidebar())}
                >
                  {linkText}
                </NavLink>
                {subLinks?.map(({ id, linkText, url }) => (
                  <ul key={id} className="mt-2 text-center">
                    <NavLink
                      to={url}
                      className="text-xl font-extrabold dark:text-gray-400 hover:text-blue-400 dark:hover:text-blue-300 transition-colors duration-300 block"
                      onClick={() => dispatch(closeSidebar())}
                    >
                      {linkText}
                    </NavLink>
                  </ul>
                ))}
              </li>
            ))}
          </div>
        </ul>
      </div>
    </>
  );
};

export default AllNavbar;
